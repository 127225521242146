// Import styles
import './sass/style.scss';

// Import polyfills
import './js/polyfills';
import './js/alpine';
import './js/scripts';
import './js/vue';
import { RunThemeExtensions } from "wly-theme-extensions";

RunThemeExtensions({
    tools: {
        scrollToAnchorOptions: {
            offset: document.querySelector('header')?.clientHeight || 0,
        }
    }
})