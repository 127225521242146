// Prevent Google Fonts from being loaded

const head = document.getElementsByTagName('head')[0];

// Save the original method
const insertBefore = head.insertBefore;

// Replace it!
head.insertBefore = function (newElement, referenceElement) {
    if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com') === 0) {
        console.warn('Prevented font from loading: ' + newElement.href);
        return;
    }

    insertBefore.call(head, newElement, referenceElement);
};
